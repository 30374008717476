// The purpose of this hook is to handle all behaviour data fetching in one place.
// It should be smart enough to decide when it needs to go to the API and when the data requested is already in redux.
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  getHubBathroomActivity,
  getHubBedroomAnalysis,
  getHubTemperatureAnalysisV3,
  getHubIndependenceAnalysis,
  getHubTimeOutsideV2,
  getHubMovementAnalysisV3,
  getHubSustenanceAnalysisV2,
  getHubMainDoorActivity,
  getHubRiskScoreV2,
} from "@intelligentlilli/api-layer";
// Utils
import {
  getCombinedRiskScoreData,
  getCombinedBehaviourAnalysisData,
  getCombinedContinuousData,
  isMissingEventData,
  getCombinedMainDoorActivity,
} from "@intelligentlilli/lilli-utils/";
import {
  isAfter,
  isSameDay,
  subDays,
  subMonths,
  addDays,
  isBefore,
} from "date-fns";
import { setLoading } from "../../State/slices/session-slice";
import { updateServiceUserData } from "../../State/slices/serviceUsersData-slice";

// Event Log Data APIs:
//  getHubBathroomActivity - uses "getCombinedBathroomInference"
//  getHubMainDoorActivity - uses "getCombinedMainDoorActivity"
//  getHubBedroomAnalysis - uses "getCombinedBehaviourAnalysisData"
// NOTE:
// "isMissingEventData" is used on bathroomActivity and mainDoorActivity because they check if the "isComplete" property is set to true
// "dailyDataMissing" is used on movementAnalysis and bedroomAnalysis since the property "isComplete" is NOT present

// Define all the behaviours that:
// 1) have similar data structure and can be processed in the same way,
// i.e. is able to rely on this utility function, "dailyDataMissing" and "getCombinedBehaviourAnalysisData"
// 2) should be fetched when on the "overview" tab
const allBehaviours = [
  "movementV3",
  "sustenance",
  "independence",
  "temperature",
];

// This key tells us the name of the payload which returns from the API call. e.g. the response for movementAnalysis will be in the format: res.movementAnalysis
const payloadBehaviourKeys = {
  movementV3: "movementAnalysis",
  sustenance: "sustenanceAnalysis",
  independence: "independenceAnalysis",
  temperature: "temperatureAnalysis",
};

// For each behaviour we have a key to access that data in redux. These must be unique: this matters especially when we have multiple versions of an API which would have the same payloadKey
const reduxBehaviourKeys = {
  movementV3: "movementAnalysisV3",
  sustenance: "sustenanceAnalysis",
  independence: "independenceAnalysis",
  temperature: "temperatureAnalysis",
};

// An object linking the relevant API call to the chosen behaviour. This will allow us to call the correct API with just the behaviour key prop
const behaviourAPIs = {
  movementV3: getHubMovementAnalysisV3,
  sustenance: getHubSustenanceAnalysisV2,
  independence: getHubIndependenceAnalysis,
  temperature: getHubTemperatureAnalysisV3,
};

// This function returns true if we have missing data for the requested day and is used in our check as to whether we need to go to the API
const dailyDataMissing = (dailyResults, dateToCheck) => {
  return !dailyResults?.find((element) =>
    isSameDay(new Date(element.date), dateToCheck)
  );
};

export const timeOutsideV2SwitchDate = new Date("2025-01-21");
/* Independence analysis data is being replaced by time outside V1 data as of 2025-01-21. 
  Therfore any request entirely after this date should not fetch independence data. (startDate > 2025-01-21)
  Similarly any request entirely before this date should not fetch time outside data. (endDate < 2025-01-21)
  Any request that spans this date should fetch both independence and time outside data. (startDate < 2025-01-21 < endDate)
  The UI will then be responsible for displaying the correct data based on the date.
*/
export async function useFetchBehaviours({
  endDateString,
  startDateString,
  dispatch,
  serviceUserId,
  behaviour, // this should be renamed to "route or tab"...
  server,
}) {
  const serviceUserData = useSelector(
    (state) => state.serviceUsersData[serviceUserId],
    isEqual
  );

  /*--- other useful variables --- */
  // When refreshing the app on the lilli user page we need to wait until the initial data fetch has set up the state store
  // before we attempt to save the behaviour data to it.
  const stateReadyToAcceptData = !!serviceUserData;
  const stateReadyToAcceptDataRef = useRef(); // A ref to control behaviour data fetching
  const behaviourRef = useRef();
  /* Adding in references for the to and from dates as new date instances every render can cause 
  useEffects to trigger even when the new date instance is the "same" */
  const endDateStringRef = useRef();
  const startDateStringRef = useRef();

  const serviceUserIdRef = useRef(serviceUserId);

  useEffect(() => {
    const startDate = startDateString && new Date(startDateString);
    const endDate = endDateString && new Date(endDateString);

    const fetchBehaviourData = async () => {
      // Our designs compare activity over two months, so fetch 2 months of data
      const DAYS_TO_FETCH = 62;
      // Construct an array of behaviours we want to fetch data for
      const behaviours =
        behaviour === "overview" || behaviour === "event log"
          ? allBehaviours
          : behaviour === "day-time" || behaviour === "night-time"
            ? ["movementV3"]
            : allBehaviours.includes(behaviour)
              ? [behaviour]
              : [];

      // Create an array to hold the API calls (promises) for any behaviour data we don't already have in redux
      const promises = [];

      // The code below will either fetch ALL behvaiours (while on the 'overview' page)
      // OR it fetches a single behaviour if we are on that particular tab.
      // * The exception to this is the Bathroom Chart.
      // We make a request to the /bathroom-analysis endpoint here
      // And further down in the code we also make a request to the /bathroom-activities endpoint when we are on the "overivew" or "bathroom" tabs
      // However, the request to /bathroom-activities is made separately because it has a different data structure and needs to be handled differently

      // Iterate over the behaviours required
      behaviours.forEach((behaviourSelected) => {
        if (
          behaviourSelected === "independence" &&
          isAfter(startDate, timeOutsideV2SwitchDate)
        ) {
          // Independence analysis data was replaced by time outside V1 data as of 2025-01-21.
          return;
        }
        /*
      Get the daily behaviour data for a particular service user
      1- get the 'behaviourKey' - this is the key we use to access and store behaviour data in Redux
      2- get the array of daily results for that behaviour
    */
        const payloadBehaviourKey = payloadBehaviourKeys[behaviourSelected];
        const reduxBehaviourKey = reduxBehaviourKeys[behaviourSelected];
        const oldDailyResults =
          serviceUserData?.[reduxBehaviourKey]?.dailyResults;

        // Iterate over the days the user is attempting to view and see if we have all the data
        let dateToCheck1 = endDate; // Starting at the end date and work back to the start date
        while (
          isAfter(dateToCheck1, startDate) ||
          isSameDay(dateToCheck1, startDate)
        ) {
          if (dailyDataMissing(oldDailyResults, dateToCheck1)) {
            const startDateString_30days = subDays(
              subMonths(endDate, 1),
              1
            ).toISOString(); // only needed for v3 temperature api endpoint
            const startDateString_60days = subDays(
              subMonths(endDate, 2),
              1
            ).toISOString();
            const endDateString = endDate.toISOString();
            // Add this behavior to the API calls that need to be made
            promises.push(
              behaviourAPIs[behaviourSelected](
                server,
                serviceUserId,
                behaviourSelected === "temperature"
                  ? startDateString_30days
                  : behaviourSelected === "movementV3"
                    ? startDateString_60days
                    : DAYS_TO_FETCH, // v2 temperature API uses 'startDate', not 'days'
                endDateString,
                "web"
              ).then((res) => {
                if (res.body?.[payloadBehaviourKey]?.dailyResults?.length > 0) {
                  const combined = getCombinedBehaviourAnalysisData(
                    serviceUserData,
                    payloadBehaviourKey,
                    res.body
                  );
                  dispatch(
                    updateServiceUserData({
                      hubId: serviceUserId,
                      update: {
                        [`${reduxBehaviourKeys[behaviourSelected]}`]: combined,
                      },
                    })
                  );
                }
              })
            );
            // There's no need to continue checking this behaviour as, if any day is missing, we make the API call. So we can break out of the loop here
            break;
          }
          // Finally iterate the date to check back one day.
          dateToCheck1 = subDays(dateToCheck1, 1);
        }
      });

      /*
      Bedroom analysis data - Processed in the same way as the other behaviours on the "overview" tab. 
    */
      if (behaviour === "event log") {
        // 1- Get the old daily results array
        const oldBedroomAnalysisDailyResults =
          serviceUserData?.bedroomAnalysis?.dailyResults || [];
        // 2- Iterate over the days the user is attempting to view and see if we have all the data
        let dateToCheck6 = endDate; // Starting at the end date and work back to the start date
        while (
          isAfter(dateToCheck6, startDate) ||
          isSameDay(dateToCheck6, startDate)
        ) {
          if (dailyDataMissing(oldBedroomAnalysisDailyResults, dateToCheck6)) {
            const endDateString = endDate.toISOString();
            // Add this behavior to the API calls that need to be made
            promises.push(
              getHubBedroomAnalysis(
                server,
                serviceUserId,
                31,
                endDateString,
                "web"
              ).then((res) => {
                if (res.body?.bedroomAnalysis?.dailyResults?.length > 0) {
                  const combined = getCombinedBehaviourAnalysisData(
                    serviceUserData,
                    "bedroomAnalysis",
                    res.body
                  );
                  dispatch(
                    updateServiceUserData({
                      hubId: serviceUserId,
                      update: {
                        bedroomAnalysis: combined,
                      },
                    })
                  );
                }
              })
            );
            // There's no need to continue checking this behaviour as, if any day is missing, we make the API call. So we can break out of the loop here
            break;
          }
          // Finally iterate the date to check back one day.
          dateToCheck6 = subDays(dateToCheck6, 1);
        }
      }

      /*
    v1 handling bathroom-activity (aka bathroom inference) API
  */
      if (behaviour === "overview" || behaviour === "bathroom") {
        // 1 - Get the bathroom inference data from Redux
        const bathroomInferenceData = serviceUserData?.bathroomInference;
        // 2- Iterate over the days the user is attempting to view and see if we have all the data
        let dateToCheck2 = endDate; // Starting at the end date and work back to the start date
        while (
          isAfter(dateToCheck2, startDate) ||
          isSameDay(dateToCheck2, startDate)
        ) {
          if (isMissingEventData(bathroomInferenceData, dateToCheck2)) {
            const startDate = subDays(endDate, DAYS_TO_FETCH);
            promises.push(
              getHubBathroomActivity(
                server,
                serviceUserId,
                startDate.toISOString(),
                endDate.toISOString(),
                "web"
              ).then((res) => {
                if (res.body?.bathroomActivities) {
                  // Combine and remove duplicates
                  const combined = getCombinedContinuousData(
                    serviceUserData?.bathroomInference || [],
                    res.body.bathroomActivities,
                    startDate,
                    endDate
                  );
                  dispatch(
                    updateServiceUserData({
                      hubId: serviceUserId,
                      update: {
                        bathroomInference: combined,
                      },
                    })
                  );
                }
              })
            );
            // There's no need to continue checking this behaviour as, if any day is missing, we make the API call. So we can break out of the loop here
            break;
          }
          // Finally iterate the date to check back one day.
          dateToCheck2 = subDays(dateToCheck2, 1);
        }
      }

      /*
    v2 handling time outside V2 (aka independence) API after the switch date
  */
      if (
        (behaviour === "overview" || behaviour === "independence") &&
        isAfter(endDate, timeOutsideV2SwitchDate)
      ) {
        // 1 - Get the exiting data from Redux
        const timeOutsideV2Data = serviceUserData?.timeOutsideV2;
        // 2- Iterate over the days the user is attempting to view and see if we have all the data
        let dateToCheck2 = endDate; // Starting at the end date and work back to the start date
        const startDateIncludingPrevious = subMonths(startDate, 1);
        while (
          isAfter(dateToCheck2, startDate) ||
          isSameDay(dateToCheck2, startDate)
        ) {
          if (isMissingEventData(timeOutsideV2Data?.insights, dateToCheck2)) {
            promises.push(
              getHubTimeOutsideV2(
                server,
                serviceUserId,
                startDateIncludingPrevious.toISOString(),
                endDate.toISOString(),
                "web"
              ).then((res) => {
                if (res.body?.insights) {
                  // Combine and remove duplicates
                  const combinedInsights = getCombinedContinuousData(
                    serviceUserData?.timeOutsideV2?.insights || [],
                    res.body.insights,
                    startDateIncludingPrevious,
                    endDate
                  );
                  dispatch(
                    updateServiceUserData({
                      hubId: serviceUserId,
                      update: {
                        timeOutsideV2: {
                          insights: combinedInsights,
                          currentState: res.body.currentState,
                        },
                      },
                    })
                  );
                }
              })
            );
            // There's no need to continue checking this behaviour as, if any day is missing, we make the API call. So we can break out of the loop here
            break;
          }
          // Finally iterate the date to check back one day.
          dateToCheck2 = subDays(dateToCheck2, 1);
        }
      }

      /*
    v1 handling main door activity API
  */
      if (behaviour === "event log" || behaviour === "overview") {
        // Get the main door activity data from Redux
        const mainDoorActivityData = serviceUserData?.mainDoorActivity || [];
        // Create the variables for holding the date range we are missing.
        let startDateToFetch;
        let endDateToFetch;

        // Find the last date in the required range for which we have incomplete/missing main door data
        let dateToCheck3 = endDate; // Starting at the end date and work back to the start date
        while (
          isAfter(dateToCheck3, startDate) ||
          isSameDay(dateToCheck3, startDate)
        ) {
          if (isMissingEventData(mainDoorActivityData, dateToCheck3)) {
            endDateToFetch = dateToCheck3;
            // Break here as we have found a date with missing data
            break;
          }
          // Iterate the date to check the previous day.
          dateToCheck3 = subDays(dateToCheck3, 1);
        }

        // Find the first date in the required range for which we have incomplete/missing main door data
        let dateToCheck4 = startDate; // Starting at the start date and working forwards to the end date
        while (
          isBefore(dateToCheck4, endDate) ||
          isSameDay(dateToCheck4, endDate)
        ) {
          if (isMissingEventData(mainDoorActivityData, dateToCheck4)) {
            startDateToFetch = dateToCheck4;
            // There's no need to continue checking this behaviour as, if any day is missing, we make the API call. So we can break out of the loop here
            break;
          }
          // Iterate the date to check the previous day.
          dateToCheck4 = addDays(dateToCheck4, 1);
        }
        // If we have identified a date range with missing data fetch it here.
        if (startDateToFetch && endDateToFetch) {
          promises.push(
            getHubMainDoorActivity(
              server,
              serviceUserId,
              startDateToFetch.toISOString(),
              endDateToFetch.toISOString(),
              "web"
            ).then((res) => {
              if (res.body?.openedAt) {
                // Combine and remove duplicates
                const combined = getCombinedMainDoorActivity(
                  mainDoorActivityData,
                  res.body,
                  startDateToFetch,
                  endDateToFetch
                );
                dispatch(
                  updateServiceUserData({
                    hubId: serviceUserId,
                    update: {
                      mainDoorActivity: combined,
                    },
                  })
                );
              }
            })
          );
        }
      }

      /* 
    Only send requests for risk-score data if we are on the 'overview' page or on a specific behaviour tab.
  */
      if (behaviour === "overview" || allBehaviours.includes(behaviour)) {
        // We also require risk scores for the specified date range. Get the current risk scores in redux
        const riskScores = serviceUserData?.riskScores;
        let dateToCheck5 = endDate;
        while (isAfter(dateToCheck5, startDate)) {
          if (dailyDataMissing(riskScores, dateToCheck5)) {
            const RISKSCORE_DAYS = 31;
            // Add this behavior to the API calls that need to be made
            promises.push(
              getHubRiskScoreV2(
                server,
                serviceUserId,
                RISKSCORE_DAYS,
                endDate.toISOString(),
                "web"
              ).then((res) => {
                // We don't want to dispatch data to redux unless we got a 200 response with a populated array
                if (res.ok && res.body?.dayRatings?.length > 0) {
                  // TODO If we request data before the installation date we get a 200 response with an empty array. In this case we hit an infinite loop because the page re-renders (not sure why as state updates are equal) as behaviour data for the days requested is not there. The data fetching logic should be smart enough to handle this situation even without a valid installation date. We don't want to artificially add to the data array with null values.
                  // TODO possible solution: if the response array does not contain the earliest date in the array then we've gone before the installation date. We could then persist this as the installation date and prevent fetching for missing data before that date
                  const combined = getCombinedRiskScoreData(
                    serviceUserData,
                    "riskScores",
                    res?.body?.dayRatings
                  );
                  dispatch(
                    updateServiceUserData({
                      hubId: serviceUserId,
                      update: { riskScores: combined },
                    })
                  );
                }
              })
            );
            // There's no need to continue checking risk data as if any day is missing we make the API call. So we can break out of the loop here
            break;
          }
          // Finally iterate the date to check back one day.
          dateToCheck5 = subDays(dateToCheck5, 1);
        }
      }

      // Once we have an array of API calls we have made we can await them here
      await Promise.all(promises)
        .catch((err) => {
          console.log("This is the error", err);
          dispatch(setLoading(false));
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    };

    if (
      stateReadyToAcceptData !== !!stateReadyToAcceptDataRef.current ||
      (serviceUserId &&
        startDateString &&
        endDateString &&
        ((stateReadyToAcceptData && behaviourRef.current !== behaviour) ||
          startDateStringRef.current !== startDateString ||
          endDateStringRef.current !== endDateString ||
          serviceUserIdRef.current !== serviceUserId))
    ) {
      fetchBehaviourData();
    }
    // Update the old references so that any re-renders do not artificially re-trigger this effect
    serviceUserIdRef.current = serviceUserId;
    endDateStringRef.current = endDateString;
    startDateStringRef.current = startDateString;
    behaviourRef.current = behaviour;
    stateReadyToAcceptDataRef.current = stateReadyToAcceptData;
  }, [
    server,
    dispatch,
    serviceUserData,
    startDateString,
    endDateString,
    serviceUserId,
    behaviour,
    stateReadyToAcceptData,
  ]);
}
