import { useEffect, useState } from "react";
// UTILS
import { extractDailyIndependenceData } from "@intelligentlilli/lilli-utils";
// DateTime
import {
  subDays,
  subMonths,
  endOfDay,
  isSameDay,
  isBefore,
  isAfter,
} from "date-fns";

export const usePrepareIndependenceData = (
  independenceAnalysis,
  mainDoorActivity,
  startDateString,
  endDateString,
  dateType
) => {
  const [independenceData, setIndependenceData] = useState();
  const [independenceDataPrevious, setIndependenceDataPrevious] = useState();
  const [frontDoorEvents, setFrontDoorEvents] = useState();

  useEffect(() => {
    const endDate = endDateString && endOfDay(new Date(endDateString));
    const startDate = startDateString && endOfDay(new Date(startDateString));

    independenceAnalysis &&
      setIndependenceData(
        extractDailyIndependenceData(
          independenceAnalysis?.dailyResults,
          subDays(startDate, 1),
          endDate
        )
      );

    // Get the independence data for the previous period (day, week or month). This is used to display the previous time outside element in the independence view
    independenceAnalysis &&
      setIndependenceDataPrevious(
        extractDailyIndependenceData(
          independenceAnalysis?.dailyResults,
          dateType === "week" ? subDays(startDate, 6) : subMonths(startDate, 1),
          dateType === "week" ? subDays(startDate, 6) : subMonths(endDate, 1)
        )
      );

    // Get the main door events
    mainDoorActivity &&
      setFrontDoorEvents(
        mainDoorActivity?.filter(
          (day) =>
            (isBefore(new Date(day.date), endDate) &&
              isAfter(new Date(day.date), startDate)) ||
            isSameDay(new Date(day.date), startDate) ||
            isSameDay(new Date(day.date), endDate)
        )
      );
  }, [
    independenceAnalysis,
    mainDoorActivity,
    endDateString,
    startDateString,
    dateType,
  ]);

  return {
    independenceData,
    independenceDataPrevious,
    frontDoorEvents,
  };
};
