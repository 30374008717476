import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import theme from "../../../Styles/theme.scss";
import { useParams, useNavigate } from "react-router-dom";
// State
import { useSelector } from "react-redux";
// API
import { postHubValidateProvisioning } from "@intelligentlilli/api-layer";
// Hooks
import {
  useTimer,
  useFade,
  useGetSensorStatus,
  useNativeInstallAuth,
} from "../../../Services/Hooks";
// Components
import SensorCard from "../../../Components/InstallationNew/SensorCard";
import SensorsHeader from "../../../Components/InstallationNew/SensorsHeader";
import InstallationCard from "../../../Components/InstallationCard";
import InstallationSplash from "../../../Components/InstallationSplash";
import { useViewport } from "../../../Components/ViewportProvider";
import InstallLoadingBubble from "../../../Components/InstallLoadingBubble";
import NewStyleModal from "../../../Components/NewStyleModal";
import MobileDrawer from "../../../Components/MobileDrawer";
import SensorGuide from "../../../Components/InstallationNew/SensorGuide";
// Icons
import {
  ThumbsUpIcon,
  ThumbsDownIcon,
} from "../../../Styles/Icons/DesignSystem";
// Config
import { getInitials } from "@intelligentlilli/lilli-utils";
// Constants
import { INSTALL_INSTRUCTIONS } from "../../../Services/Constants";
import { SERVER } from "../../../Services/config";

const InstallHubProvisioning = ({ goBackLink, baseURL }) => {
  const { devicestring, id } = useParams(); // Get the service user id from the url parameter
  const navigate = useNavigate();
  const { width } = useViewport();
  const isDesktop = width >= 900;
  // su details
  const serviceUsers = useSelector((state) => state.serviceUsers);
  const serviceUser = serviceUsers.find((i) => i.id === parseInt(id));
  const serviceUserInitials = getInitials(serviceUser?.userstring) || "";
  const [stage, setStage] = useState("start"); // start | connecting
  const [provisioningStage, setProvisioningStage] = useState("unknown"); // unknown | success | fail
  // We wait for the hub to become provisioned
  const { minutes, seconds, setMinutes } = useTimer({
    initialMinutes: 0,
    initialSeconds: 0,
  });
  const timesUp = minutes === 0 && seconds === 0;

  // Set cookie headeres for native install
  useNativeInstallAuth({ path: "install/native/link" });

  // Trigger the sensor status fetching
  const { hubIsOnline, loading, sensors, error } = useGetSensorStatus({
    devicestring: devicestring,
    id: id,
    continuous: !timesUp,
  });

  const showSensorHeader = !(
    stage === "connecting" &&
    ((hubIsOnline && provisioningStage === "success") ||
      (hubIsOnline && provisioningStage === "fail") ||
      (timesUp && !hubIsOnline))
  );

  // Once the hub is online, make sure the hub is correctly provisioned
  // Only run this twice, 15 seconds apart
  // If it fails the second time, fail the install
  useEffect(() => {
    const validateProvisioning = async (server, id, origin, attempt = 1) => {
      try {
        const hubVP = await postHubValidateProvisioning(server, id, origin);
        if (hubVP.ok) {
          if (hubVP.body.provisioned === true) {
            console.log("Hub is provisioned correctly.");
            setProvisioningStage("success");
          } else if (hubVP.body.provisioned === false && attempt === 2) {
            console.error("Hub is not provisioned correctly.");
            setProvisioningStage("fail");
          } else if (hubVP.body.provisioned === false && attempt < 2) {
            const FIFTEEN_SECONDS = 15000;
            console.log(
              `Provisioning not complete. Retrying in 15 seconds... (Attempt ${attempt})`
            );
            await new Promise((resolve) =>
              setTimeout(resolve, FIFTEEN_SECONDS)
            );
            return validateProvisioning(server, id, origin, attempt + 1);
          }
        } else {
          console.error(
            "Res not Ok, failed to validate hub provisioning.",
            hubVP?.body
          );
          setProvisioningStage("fail");
        }
      } catch (error) {
        console.error("Error during provisioning validation:", error);
        setProvisioningStage("fail");
      }
    };

    if (hubIsOnline) {
      validateProvisioning(SERVER, id, "web");
    }
  }, [hubIsOnline, devicestring, id]);

  // Installation guide
  const [isGuideVisible, setShowAnimation, showAnimation] = useFade(false, 150);
  const instructions = INSTALL_INSTRUCTIONS["h1"];

  return (
    <>
      <InstallationCard style={styles.card}>
        <div className={styles.card_top}>
          <div className={styles.desktop_right}>
            {!isDesktop && showSensorHeader && (
              <SensorsHeader
                isHub={true}
                hubIsOnline={hubIsOnline}
                loading={stage === "connecting"}
              />
            )}
            {stage === "start" && (
              <SensorCard
                isHub={true}
                error={error}
                sensor={sensors?.devices?.[0]} // this is the hub
                sensors={sensors?.devices}
                showMissingLocationMessage={false}
                sensorLocations={null}
                loading={loading}
                onConnect={() => {
                  setMinutes(6);
                  setStage("connecting");
                }}
                onViewGuide={() => {
                  setShowAnimation(true);
                }}
              />
            )}
            {stage === "connecting" &&
              ((!hubIsOnline && !timesUp) ||
                (hubIsOnline && provisioningStage === "unknown")) && (
                <InstallLoadingBubble initials={serviceUserInitials} />
              )}
            {stage === "connecting" &&
              hubIsOnline &&
              provisioningStage === "success" && (
                <InstallationSplash
                  icon={<ThumbsUpIcon color={theme.expected2} />}
                  headers={["Great!", "Your Lilli hub is connected"]}
                  message={["Let's get your sensors installed"]}
                  goBackButtonLabel={"Exit install"}
                  goBackOnClick={() => navigate(goBackLink)}
                  nextButtonLabel={"Continue"}
                  nextButtonOnClick={() =>
                    navigate(`${baseURL}/sensors/${id}/${devicestring}`)
                  }
                  isDesktop={isDesktop}
                />
              )}
            {stage === "connecting" &&
              hubIsOnline &&
              provisioningStage === "fail" && (
                <InstallationSplash
                  icon={<ThumbsDownIcon color={theme.risk3} />}
                  headers={["The hub was not correctly provisioned"]}
                  message={[
                    "Although the hub is online, the hub is not provisioned to expect all six sensors.",
                    "You won't be able to continue with this install.",
                    "Please contact support with this message. Send an email to ",
                  ]}
                  email={"support@intelligentlilli.com"}
                  goBackButtonLabel={"Exit install"}
                  goBackOnClick={() => navigate(goBackLink)}
                  isDesktop={isDesktop}
                />
              )}
            {stage === "connecting" && timesUp && !hubIsOnline && (
              <InstallationSplash
                icon={<ThumbsDownIcon color={theme.risk3} />}
                headers={["We could not connect the hub"]}
                troubleshooting={[
                  "Let's try again. Please do not close the app while you complete this step.",
                  "1. Make sure the hub is plugged in and the outlet has power.",
                  "2. Try placing the hub in a different area of the house, away from the radiator, TV, wifi router, fridge, or microwave.",
                  "3. Try using the ethernet cable to connect the hub. You may be in an area of low connectivity.",
                  "4. Click 'Try again' to restart the connection process.",
                ]}
                message={["If the problem continues, contact "]}
                email={"support@intelligentlilli.com"}
                goBackButtonLabel={"Exit install"}
                goBackOnClick={() => navigate(goBackLink)}
                nextButtonLabel={"Try again"}
                nextButtonOnClick={() => setMinutes(6)}
                isDesktop={isDesktop}
              />
            )}
          </div>
          <div className={styles.desktop_left}>
            {isDesktop && showSensorHeader && (
              <SensorsHeader
                isHub={true}
                hubIsOnline={hubIsOnline}
                loading={stage === "connecting"}
              />
            )}
          </div>
        </div>
      </InstallationCard>
      {isGuideVisible && isDesktop ? (
        <NewStyleModal
          hide={() => {
            setShowAnimation(false);
          }}
          showCloseIcon={true}
          useFade={false}
          showAnimation={showAnimation}
          size={"medium"}
        >
          <SensorGuide
            isDesktop={isDesktop}
            instructions={instructions}
            isMotion={false}
            setShowAnimation={setShowAnimation}
          />
        </NewStyleModal>
      ) : (
        <MobileDrawer
          closeModal={() => {
            setShowAnimation(false);
          }}
          showGuide={showAnimation}
        >
          <SensorGuide
            isHub={true}
            isDesktop={isDesktop}
            instructions={instructions}
            isMotion={false}
            setShowAnimation={setShowAnimation}
          />
        </MobileDrawer>
      )}
    </>
  );
};

export default InstallHubProvisioning;
