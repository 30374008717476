import { useEffect, useState } from "react";
// UTILS
import { endOfDay } from "date-fns";

// This hook is used to generate data that are used in the Event Log
export const usePrepareTimeOutsideData = ({
  reduxTimeOutsideData,
  urlStartDateString,
  urlEndDateString,
  urlDateType,
  prevEndDateString,
  prevStartDateString,
}) => {
  const [timeOutsideData, setTimeOutsideData] = useState([]);
  const [timeOutsideDataPrevious, setTimeOutsideDataPrevious] = useState([]);

  useEffect(() => {
    const endDate = urlEndDateString && endOfDay(new Date(urlEndDateString));
    const startDate =
      urlStartDateString && endOfDay(new Date(urlStartDateString));

    const prevEndDate =
      prevEndDateString && endOfDay(new Date(prevEndDateString));
    const prevStartDate =
      prevStartDateString && endOfDay(new Date(prevStartDateString));

    setTimeOutsideData(
      getRelevantTimePeriod(reduxTimeOutsideData?.insights, startDate, endDate)
    );
    setTimeOutsideDataPrevious(
      getRelevantTimePeriod(
        reduxTimeOutsideData?.insights,
        prevStartDate,
        prevEndDate
      )
    );
  }, [
    reduxTimeOutsideData,
    urlStartDateString,
    urlEndDateString,
    urlDateType,
    prevEndDateString,
    prevStartDateString,
  ]);

  return {
    timeOutsideData,
    timeOutsideDataPrevious,
  };
};

// Functions that help with extracting and processing bathroom activity data
const getRelevantTimePeriod = (array, startDate, endDate) => {
  return (
    array?.filter((event) => {
      return (
        endOfDay(new Date(event?.date)) >= new Date(startDate) &&
        endOfDay(new Date(event?.date)) <= new Date(endDate)
      );
    }) || []
  );
};
